var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper panel-ssh pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "Shell/SSH" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              loading: _vm.loading,
                              headers: _vm.headersList,
                              items: _vm.nodeList,
                              "hide-actions": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "items",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("td", [_vm._v(_vm._s(item.name))]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.type || "--"))
                                    ]),
                                    _c("td", [
                                      item.status === "Running"
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#028e01" }
                                            },
                                            [_vm._v("Running")]
                                          )
                                        : _vm._e(),
                                      item.status === "Startup"
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#ff5a01" }
                                            },
                                            [_vm._v("Startup")]
                                          )
                                        : _vm._e(),
                                      item.status === "Failed"
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#8e0000" }
                                            },
                                            [_vm._v("Failed")]
                                          )
                                        : _vm._e()
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.clientInfo
                                                                  .status !==
                                                                "Good",
                                                              flat: "",
                                                              icon: "",
                                                              color: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openShell(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "play_circle_outline"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [_c("span", [_vm._v("Connect")])]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              flat: "",
                                                              icon: "",
                                                              color: "green"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.$refs.reboot.open(
                                                                  { item: item }
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "settings_backup_restore"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [_c("span", [_vm._v("Reboot")])]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "no-data",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "min-height": "192px",
                                          position: "relative"
                                        }
                                      },
                                      [_c("Empty")],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("Help", {
                            attrs: {
                              mod: ["menu_tab_ssh"],
                              product: _vm.appInfo.product_name
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "Confirm",
                    {
                      ref: "reboot",
                      attrs: { "max-width": "600px" },
                      on: { confirm: _vm.rebootHandler },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function(ref) {
                            var options = ref.options
                            return [
                              options.item && options.item.name
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#666" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style: {
                                                color:
                                                  _vm.$vuetify.theme.primary
                                              }
                                            },
                                            [_vm._v(_vm._s(options.item.name))]
                                          ),
                                          _vm._v(
                                            " reboot may take 1-2 minutes.\n                             Please try reloading the page to see the results. During the time, services in the container will not be available.\n                        "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-subheader",
                                        { staticClass: "panel-title" },
                                        [_vm._v("Do you want to continue ?")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("Reboot")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }