<template>
    <v-container class="panel-wrapper panel-ssh pa-0" grid-list-lg fluid >
        <v-layout column>
            <v-flex>
                <TabCard title="Shell/SSH">
                    <v-layout wrap >
                        <v-flex xs12>
                            <v-data-table
                                    :loading="loading"
                                    :headers="headersList"
                                    :items="nodeList"
                                    class="elevation-1"
                                    hide-actions>
                                <template v-slot:items="{item}">
                                    <td>{{ item.name }}</td>
                                    <td >{{ item.type || '--' }}</td>
                                    <td>
                                        <span style="color: #028e01" v-if="item.status=== 'Running'">Running</span>
                                        <span style="color: #ff5a01" v-if="item.status=== 'Startup'">Startup</span>
                                        <span style="color: #8e0000;" v-if="item.status=== 'Failed'">Failed</span>
                                    </td>
                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn :disabled="clientInfo.status!=='Good'"  flat  icon color="primary" v-on="on" @click="openShell(item)">
                                                    <v-icon >play_circle_outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Connect</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn flat icon color="green" v-on="on" @click="$refs.reboot.open({item})">
                                                    <v-icon>settings_backup_restore</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Reboot</span>
                                        </v-tooltip>
                                    </td>
                                </template>
                                <template v-slot:no-data>
                                    <div style="min-height: 192px;position: relative;">
                                        <Empty></Empty>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-flex>
                        <v-flex xs12>
<!--                            <template v-if="clientInfo.status!=='Good'">-->
<!--                                <p>-->
<!--                                    For the security purpose, only the account holders who pass the verification will be granted permission to web shell. Please <router-link to="/profile/account">Update Billing information</router-link> to start the process.(typically takes 2 hours).-->
<!--                                </p>-->
<!--                            </template>-->
<!--                            <template v-if="clientInfo.status==='Good'">-->
<!--                                <ul>-->
<!--                                    <li>-->
<!--                                        You can issue commands as if you are inside the container and perform local operations like monitoring, debugging, and using CLI commands specific to what is running in the container.-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        Only the settings related to your application will be permanently saved.-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        Please click the “Reboot” button in our Client Panel if you want to reboot the container.-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        Any abuse of the web shell service will result in the revocation of shell access.-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </template>-->

                            <Help :mod="['menu_tab_ssh']" :product="appInfo.product_name"></Help>

                        </v-flex>
                    </v-layout>
                    <Confirm ref="reboot" @confirm="rebootHandler" max-width="600px">
                        <span slot="title">Reboot</span>
                        <template v-slot:content="{options}">
                            <div v-if="options.item&& options.item.name">
                         <span style="color: #666;">
                             <span :style="{color:$vuetify.theme.primary}">{{options.item.name}}</span> reboot may take 1-2 minutes.
                             Please try reloading the page to see the results. During the time, services in the container will not be available.
                        </span>
                                <v-subheader class="panel-title">Do you want to continue ?</v-subheader>
                            </div>
                        </template>
                    </Confirm>
                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    //import ChartCart from './_source/ChartCart.vue'
    //import Empty from '@/components/Empty.vue'
    //import Spin from '@/components/Spin.vue'
    import Confirm from '@/components/Confirm.vue'
    import TabCard from '@/components/TabCard.vue'
    import {mapState, mapGetters,mapActions} from 'vuex'
    import Help from '@/components/Help'

    export default {
        name: "WebShell",
        components: { Confirm,TabCard ,Help},
        data() {
            return {
                loading : false,
                headersList: [
                    {text: 'Instances', value: '', sortable: false, align: 'left'},
                    {text: 'Type', value: '', sortable: false, align: 'left'},
                    {text: 'Status', value: '', sortable: false, align: 'left'},
                    {text: 'Action', value: '', sortable: false, align: 'left'},
                ]
            }
        },
        computed: {
            nodeList() {
                return this.stats.instance_status
            },
            stacksUuid (){
                return this.appInfo.stacks[0].uuid
            },
            ...mapState('application', ['appInfo', 'stats']),
            ...mapState('client', ['clientInfo']),
            ...mapGetters('application', ['getServiceId'])
        },
        methods: {
            rebootHandler({item}){
                return this.$http.get(`/stacks/${this.stacksUuid}/pods/${item.name}/restart/`).then(() => {
                    this.getPodStats()
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            getPodStats(){
                if(this.loading){
                    return
                }
                this.loading = true
                this.getAppStats(true).then(()=>{
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            },
            openShell(item) {
                window.open(`/shell/index.html?uuid=${this.stacksUuid}&name=${item.name}`,'shell_blank','height=400,width=750,left=100,location=0,menubar=0,titlebar=0,status=0,resizable=1',false)
            },
            ...mapActions('application', ['getAppStats'])

        },
        created() {
            this.getAppStats(true)
            this.$SDK.track({pageName:'Shell/SSH',productName:this.appInfo.product_name})
        },
    }
</script>

<style lang="scss">
    .panel-ssh {
        .search-wrapper .v-text-field--solo .v-input__control {
            min-height: 36px;
        }
        .filter .v-select__selections .v-select__selection {
            display: inline-block;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

</style>
